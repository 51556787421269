/*eslint-disable*/
// chakra imports
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Box, IconButton, useColorModeValue } from "@chakra-ui/react";
import { UiContext } from "contexts/UiContext";
import React, { useContext } from "react";
import SidebarContent from "./SidebarContent";

// FUNCTIONS

function Sidebar(props) {
  // to check for active links and opened collapses
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";

  const { routes, sidebarVariant } = props;

  const { collapse, setCollapse } = useContext(UiContext);

  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = "none";
  let sidebarRadius = "0px";
  let sidebarMargins = "0px";
  if (sidebarVariant === "opaque") {
    sidebarBg = useColorModeValue("white", "gray.700");
    sidebarRadius = "16px";
    sidebarMargins = "16px 0px 16px 16px";
  }

  // SIDEBAR
  return (
    <Box ref={mainPanel} position="absolute">
      <IconButton
        _hover={{ bg: "#ffd404" }}
        aria-label="Call Segun"
        size="xs"
        icon={collapse ? <ArrowRightIcon /> : <ArrowLeftIcon />}
        position="relative"
        top="136px"
        left={collapse ? "118px" : "295px"}
        zIndex="999"
        onClick={() => setCollapse(!collapse)}
        display={{ sm: "none", xl: "block" }}
      />
      <Box display={{ sm: "none", xl: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w={collapse ? "100px" : "260px"}
          maxW={collapse ? "100px" : "260px"}
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          borderRadius={sidebarRadius}
        >
          <SidebarContent
            routes={routes}
            display="none"
            sidebarVariant={sidebarVariant}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Sidebar;
