// Chakra Icons
// Chakra Imports
import { Button, Flex, useColorModeValue } from "@chakra-ui/react";
// Assets
// Custom Icons
// Custom Components
import PropTypes from "prop-types";
import React from "react";
import { MdLogout } from "react-icons/md";
import { logout } from "services/authService";

export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  // Chakra Color Mode
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();
  return (
    <Flex
      pe={"0px"}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <Button leftIcon={<MdLogout />} variant="solid" onClick={logout}>
        Sair
      </Button>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
