import React, { useContext, useReducer, useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { fetchExportData } from "services/ramsomService";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import * as XLSX from "xlsx/xlsx.mjs";
import { HiDocumentDownload } from "react-icons/hi";
import {
  reducer,
  initialState,
  EXPORT_CLICKED,
  EXPORT_CLICKED_OFF,
} from "./ExportReducer";
import moment from "moment";
import { UiContext } from "contexts/UiContext";

export default function Export() {
  const { onNewNotification } = useContext(UiContext);
  const [{ queryFrom, queryTo, download }, dispatch] = useReducer(
    reducer,
    initialState
  );
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const { isLoading, data: exportData, isSuccess } = useQuery(
    ["/export", queryFrom, queryTo],
    () => fetchExportData(queryFrom, queryTo),
    {
      keepPreviousData: false,
      staleTime: 10000,
    }
  );

  useEffect(() => {
    if (
      queryFrom === "Invalid date" ||
      queryTo === "Invalid date" ||
      moment(queryFrom) >
        moment(queryTo)
    ) {
      if (download) {
        onNewNotification({
          title: "Ops...",
          description: "Favor informar um período válido",
          status: "error",
          position: "top-right",
        });
      }
      dispatch({ type: EXPORT_CLICKED_OFF });
      return;
    }

    if (!isLoading && isSuccess && exportData && download) {
      const data = exportData.data;
      const flattenData = data.map((item) => objectFlatter(item));
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(flattenData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Relatório");
      XLSX.writeFile(workbook, `export_${from}_${to}.xlsx`);
      dispatch({ type: EXPORT_CLICKED_OFF });
    }
  }, [isLoading, isSuccess, exportData, download]);

  const handleOnExport = () => {
    dispatch({ type: EXPORT_CLICKED, payload: { from, to } });
  };

  const objectFlatter = (object, parentKey = null) => {
    const newObject = {};

    const getFlattenObject = (obj) => {
      if (obj === undefined) {
        return;
      }
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          if (key === "address") {
            getFlattenObject(obj[key]);
          } else {
            if (obj[key] && obj[key][0] && obj[key][0].number) {
              obj[key][0].telefone = obj[key][0].number;
              delete obj[key][0].number;
            }
            getFlattenObject(obj[key][0]);
          }
        } else {
          newObject[key] = obj[key];
        }
      });
    };

    getFlattenObject(object);
    return newObject;
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px" justifyContent="space-between">
          <Text fontSize="xl" color="gray.700" fontWeight="bold">
            Exportar
          </Text>
        </CardHeader>
        <CardBody>
          <HStack>
            <FormControl>
              <FormLabel>Início do período</FormLabel>
              <Input
                size="md"
                type="date"
                onChange={(e) => setFrom(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Fim do período</FormLabel>
              <Input
                size="md"
                type="date"
                onChange={(e) => setTo(e.target.value)}
              />
            </FormControl>

            <VStack h="100%" justifyContent={"flex-end"}>
              <Button
                isLoading={isLoading}
                backgroundColor={"#ffd404"}
                leftIcon={<HiDocumentDownload />}
                onClick={handleOnExport}
              >
                Exportar
              </Button>
            </VStack>
          </HStack>
        </CardBody>
      </Card>
    </Flex>
  );
}