import jwtDecode from "jwt-decode";
import http from "services/httpConfig";

const apiEndpoint = `/login_check`;
const tokenKey = "token";

export const login = async (credentials) => {
  const { data } = await http.post(apiEndpoint, credentials);
  localStorage.setItem(tokenKey, data.token);
};

export const logout = () => {
  localStorage.removeItem(tokenKey);
  window.location.href = "/";
};

export const getCurrentUser = () => {
  try {
    const token = localStorage.getItem(tokenKey);
    const jwt = jwtDecode(token);
    if (jwt.exp && jwt.exp * 1000 < Date.now()) return null;
    return jwt;
  } catch (ex) {
    return null;
  }
};

export default {
  login,
  logout,
  getCurrentUser,
};
