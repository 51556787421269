import { SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import RamsomSearch from "components/RamsomSearch";
import RadioCard from "components/RadioCard/RadioCard";
import Tracking from "components/Tracking";
import Sorting from "components/Sorting";
import { UiContext } from "contexts/UiContext";
import moment from "moment";
import React, {
  useEffect,
  useMemo,
  useContext,
  useReducer,
  useState,
} from "react";
import { useQuery } from "react-query";
import { usePagination, useSortBy, useTable } from "react-table";
import {
  fetchRamsomsData,
  formatQueryPageSortBy,
} from "services/ramsomService";
import {
  reducer,
  initialState,
  PAGE_CHANGED,
  PAGE_SIZE_CHANGED,
  PAGE_SORT_CHANGED,
  PAGE_FILTER_CHANGED,
  TOTAL_COUNT_CHANGED,
} from "./RamsomsReducer";

export default function Ramsoms() {
  const [
    {
      queryPageIndex,
      queryPageSize,
      totalCount,
      queryPageFilter,
      queryPageSortBy,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const [queryPageFilterType, setQueryPageFilterType] = useState("");
  const [keyword, setKeyword] = useState("");
  const [useFilter, setUseFilter] = useState(false);

  var momentBusiness = require("moment-business-days");

  momentBusiness.updateLocale("pt-BR", {
    workingWeekdays: [1, 2, 3, 4, 5],
  });

  const { onNewDetails } = useContext(UiContext);

  function handleOpenDetails(row) {
    if (row) {
      onNewDetails(row);
    }
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: "Cliente",
        accessor: "receiver",
        Cell: ({ row }) => (
          <VStack alignItems={"flex-start"}>
            <Text fontSize="md" color="gray.700" fontWeight="normal">
              {row.original.receiver}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              ID: {row.original.participantLogin}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              CPF: {row.original.document}
            </Text>
          </VStack>
        ),
      },
      {
        Header: "Produto",
        accessor: "vendorOrderModel[0].items[0].name",
      },
      {
        Header: "Resgate",
        accessor: "orderDate",
        Cell: ({ value }) => (
          <VStack alignItems={"flex-start"}>
            <Text fontSize="md" color="gray.700" fontWeight="bold">
              {moment(value).format("DD/MM/YYYY")}
            </Text>
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {momentBusiness(value).businessAdd(60).format("DD/MM/YYYY")}
            </Text>
          </VStack>
        ),
      },
      {
        Header: "NF",
        accessor: "nfNumber",
      },
      {
        Header: "Código de rastreio",
        accessor: "trackingCode",
        Cell: ({ row }) => (
          <Tracking
            id={row.original.id}
            trackingCode={row.original.trackingCode}
          />
        ),
      },
      {
        id: "actions",
        Header: "",
        Cell: (row) => {
          return (
            <IconButton
              aria-label="Search database"
              borderRadius={25}
              outlineColor={"transparent"}
              icon={<SearchIcon />}
              onClick={() => handleOpenDetails(row.row.original)}
            />
          );
        },
      },
    ],
    []
  );

  const { isLoading, error, data: ramsoms } = useQuery(
    [
      "/ramsoms",
      queryPageIndex,
      queryPageSize,
      keyword,
      queryPageFilterType,
      queryPageSortBy,
    ],
    () =>
      fetchRamsomsData(
        queryPageIndex,
        queryPageSize,
        keyword,
        queryPageFilterType,
        queryPageSortBy
      ),
    {
      keepPreviousData: false,
      staleTime: Infinity,
    }
  );

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: useMemo(() => ramsoms?.data ?? [], [ramsoms]),
      initialState: useMemo(
        () => ({
          pageIndex: queryPageIndex,
          pageSize: queryPageSize,
          sortBy: queryPageSortBy,
        }),
        []
      ),
      manualPagination: true,
      manualSortBy: true,
      pageCount: Math.ceil(ramsoms?.pagination.total / queryPageSize),
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  const options = ["5", "10", "20", "50", "100"];

  const radioPagination = useRadioGroup({
    name: "limitPagination",
    defaultValue: String(pageSize),
    onChange: setPageSize,
  });

  const group = radioPagination.getRootProps();

  const onSearching = (stateData) => {
    setKeyword(stateData.keyword);
    setQueryPageFilterType(stateData.queryPageFilterType);
  };

  const onClearing = () => {
    setKeyword("");
    setUseFilter(true);
  };

  useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize]);

  useEffect(() => {
    dispatch({
      type: PAGE_SORT_CHANGED,
      payload: formatQueryPageSortBy(sortBy, queryPageSortBy),
    });
    gotoPage(0);
  }, [sortBy]);

  useEffect(() => {
    if (useFilter || !keyword) {
      gotoPage(0);
      dispatch({ type: PAGE_FILTER_CHANGED, payload: keyword });
      setUseFilter(false);
    }
  }, [keyword, useFilter]);

  useEffect(() => {
    if (ramsoms?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.count,
      });
      gotoPage(0);
    }
  }, [ramsoms?.count]);

  if (error) {
    return <p>Error</p>;
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      {isLoading ? (
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} h="80vh">
          <Stack h="100%">
            <Skeleton height="20%" />
            <Skeleton height="10%" />
            <Skeleton height="10%" />
            <Skeleton height="10%" />
            <Skeleton height="10%" />
            <Skeleton height="10%" />
            <Skeleton height="10%" />
            <Skeleton height="20%" />
          </Stack>
        </Card>
      ) : (
        <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
          <CardHeader p="6px 0px 22px 0px" justifyContent="space-between">
            <Text fontSize="xl" color="gray.700" fontWeight="bold">
              Resgates
            </Text>
            <RamsomSearch
              keywordData={keyword}
              updateClearInput={onClearing}
              onSearching={onSearching}
            />
          </CardHeader>
          <CardBody>
            <TableContainer w={"100%"}>
              <Table variant="simple" {...getTableProps()}>
                <Thead>
                  {headerGroups.map((headerGroup) => (
                    <Tr
                      key={headerGroup.id}
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column) => (
                        <Th
                          key={column.id}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <HStack>
                            <Text>{column.render("Header")}</Text>
                            <Sorting
                              column={column}
                              selected={queryPageSortBy}
                            />
                          </HStack>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <Tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <Td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
                <TableCaption>
                  <HStack justifyContent={"space-between"}>
                    <HStack>
                      <Button
                        px={5}
                        py={6}
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        {"<<"}
                      </Button>{" "}
                      <Button
                        px={5}
                        py={6}
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        {"<"}
                      </Button>{" "}
                      <Text>
                        <Text as="b">
                          {pageIndex + 1} de {pageOptions.length}
                        </Text>{" "}
                      </Text>{" "}
                      <Button
                        px={5}
                        py={6}
                        onClick={nextPage}
                        disabled={!canNextPage}
                      >
                        {">"}
                      </Button>{" "}
                      <Button
                        px={5}
                        py={6}
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        {">>"}
                      </Button>
                    </HStack>
                    <HStack {...group}>
                      <Text>Exibição de resultados: </Text>
                      {options.map((value) => {
                        const radio = radioPagination.getRadioProps({ value });
                        return (
                          <RadioCard key={value} {...radio} px={5} py={3}>
                            {value}
                          </RadioCard>
                        );
                      })}
                    </HStack>
                  </HStack>
                </TableCaption>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      )}
    </Flex>
  );
}
