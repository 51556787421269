import http from "services/httpConfig";

export const graphic = async (rota) => {
  const { data } = await http.get(rota);
  return data;
};

export default {
  graphic,
};
