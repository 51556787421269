import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { HStack, IconButton, Input, Spinner, Text } from "@chakra-ui/react";
import { UiContext } from "contexts/UiContext";
import { trim } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { updateTrackingCode } from "services/ramsomService";

function Tracking({ id, trackingCode }) {
  const [newTrackingCode, setNewTrackingCode] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { onNewNotification } = useContext(UiContext);
  const queryClient = useQueryClient();

  useEffect(() => {
    setNewTrackingCode(trackingCode);
    setIsEditing(trackingCode !== null);
  }, [trackingCode]);

  function handleUpdateTrackingCode() {
    if (!newTrackingCode || trim(newTrackingCode) === "") {
      onNewNotification({
        title: "Ops...",
        description: "Favor informar o código de rastreio",
        status: "error",
        position: "top-right",
      });
      return;
    }
    if (newTrackingCode === trackingCode) {
      onNewNotification({
        title: "Atualizado!",
        description: "Código de rastreio atualizado com sucesso.",
        status: "success",
        position: "top-right",
      });
      setIsEditing(true);
      return;
    }
    setIsLoading(true);
    setNewTrackingCode(newTrackingCode);
    updateTrackingCode({ id, trackingCode: newTrackingCode })
      .then(async () => {
        await queryClient.refetchQueries();
        setIsEditing(true);
        setIsLoading(false);
        onNewNotification({
          title: "Atualizado!",
          description: "Código de rastreio atualizado com sucesso.",
          status: "success",
          position: "top-right",
        });
      })
      .catch((e) => {
        const { response } = e;
        setIsLoading(false);
        onNewNotification({
          title: "Ops...",
          description: response.data.messages[0],
          status: "error",
          position: "top-right",
        });
      });
  }

  function handleRemoveTrackingCode() {
    setIsEditing(false);
  }

  return isEditing ? (
    <HStack
      backgroundColor={"#ffd404"}
      borderRadius={50}
      justifyContent={"space-between"}
    >
      <Text pl={4} pr={2} color={"black"}>
        {newTrackingCode}
      </Text>
      <IconButton
        color={"black"}
        backgroundColor={"#ffd404"}
        borderRadius={50}
        icon={<EditIcon />}
        _hover={{ bg: "#edc605" }}
        onClick={handleRemoveTrackingCode}
      />
    </HStack>
  ) : (
    <HStack
      backgroundColor={"gray.100"}
      borderRadius={50}
      justifyContent={"space-between"}
    >
      <Input
        type="text"
        variant="unstyled"
        pl={4}
        pr={2}
        placeholder="Inserir código"
        value={newTrackingCode ?? ""}
        onChange={(newCode) => setNewTrackingCode(newCode.target.value)}
      />
      <IconButton
        isLoading={isLoading}
        color={"teal.400"}
        borderRadius={50}
        icon={<CheckIcon />}
        onClick={() => handleUpdateTrackingCode()}
      />
    </HStack>
  );
}

export default Tracking;
