import React from "react";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

export default function Sorting({ column, selected }) {
  const formatQueryPageSortById = (id) => {
    id = id.split(".");
    if (id.length <= 0) return id;
    switch (id.length) {
      case 1:
        return `o.${id}`;
      case 2:
        return `${id[0].substr(0, 1)}.${id[1]}`;
      default:
        return `${id[id.length - 2].substr(0, 1)}.${id[id.length - 1]}`;
    }
  };

  return (
    <span>
      {selected.length > 0 ? (
        formatQueryPageSortById(column.id) === selected[0].id ? (
          selected[0].desc ? (
            <TiArrowSortedDown />
          ) : (
            <TiArrowSortedUp />
          )
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </span>
  );
}
