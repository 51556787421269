import http from "services/httpConfig";
import moment from "moment";

export const formatQueryPageSortBy = (params, lastParam) => {
  const formatQueryPageSortById = (id) => {
    id = id.split(".");
    if (id.length <= 0) return id;
    switch (id.length) {
      case 1:
        return `o.${id}`;
      case 2:
        return `${id[0].substr(0, 1)}.${id[1]}`;
      default:
        return `${id[id.length - 2].substr(0, 1)}.${id[id.length - 1]}`;
    }
  };

  if (params.length > 0 && lastParam.length === 0) {
    params[0].id = formatQueryPageSortById(params[0].id);
    params[0].desc = false;
  }

  if (params.length > 0 && lastParam.length > 0) {
    params[0].id = formatQueryPageSortById(params[0].id);
    if (params[0].id !== lastParam[0].id) {
      params[0].desc = false;
    } else {
      if (!lastParam[0].desc) {
        params[0].desc = true;
      } else {
        return [];
      }
    }
  }

  return params;
};

export const fetchRamsomsData = async (
  queryPageIndex,
  queryPageSize,
  queryPageFilter,
  queryPageFilterType,
  queryPageSortBy
) => {
  let paramStr = "";

  if (
    queryPageFilter.trim().length > 1 &&
    queryPageFilterType.trim().length > 1
  ) {
    if (queryPageFilterType === "o.orderDate") {
      paramStr = `&criteria[${queryPageFilterType}]=${moment(
        queryPageFilter
      ).format("YYYY-DD-MM 00:00:00")}|${moment(queryPageFilter).format(
        "YYYY-DD-MM 23:59:59"
      )}`;
    } else {
      paramStr = `&criteria[${queryPageFilterType}]=${queryPageFilter}`;
    }
  }

  if (queryPageSortBy.length > 0) {
    const sortParams = queryPageSortBy[0];
    const sortByDir = sortParams.desc ? "DESC" : "ASC";
    paramStr = `${paramStr}&orderBy[${sortParams.id.replace(
      /\[0\]/g,
      ""
    )}]=${sortByDir}`;
  }

  const offset = queryPageIndex * queryPageSize;

  const { data } = await http.post(
    `/new-search/order?offset=${offset}&limit=${queryPageSize}${paramStr}`
  );

  return data;
};

export const updateTrackingCode = async ({ id, trackingCode }) => {
  const { data } = await http.put(`/order` + `/${id}/trackingcode`, {
    trackingCode,
  });
  return data;
};

export const fetchExportData = async (from, to) => {
  let paramStr = "";

  if (from && to) {
    paramStr = `criteria[o.orderDate]=${from}|${to}&limit=none&view=export`;
  }

  const { data } = await http.post(`/new-search/order?${paramStr}`);

  return data;
};

export default {
  fetchRamsomsData,
  updateTrackingCode,
  fetchExportData,
};
