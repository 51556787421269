import React, { useState } from "react";
import { SearchIcon } from "@chakra-ui/icons";
import { Button, HStack, Input, useRadioGroup } from "@chakra-ui/react";
import RadioCard from "components/RadioCard/RadioCard";
import { useEffect } from "react";

const searchOptions = [
  {
    label: "Cliente",
    value: "o.receiver",
  },
  {
    label: "CPF",
    value: "o.document",
  },
  {
    label: "ID",
    value: "o.participantLogin",
  },
  {
    label: "Pedido",
    value: "o.id",
  },
  {
    label: "Rastreio",
    value: "o.trackingCode",
  },
  {
    label: "NF",
    value: "o.nfNumber",
  },
];

export default function RamsomSearch({
  updateClearInput,
  onSearching,
  keywordData,
}) {
  const [queryPageFilterType, setQueryPageFilterType] = useState("receiver");
  const [keyword, setKeyword] = useState("");

  const handleClickButton = () => {
    if (queryPageFilterType && keyword) {
      onSearching({
        queryPageFilterType: queryPageFilterType,
        keyword: keyword,
      });
    }
  };

  const handleClearInput = (value) => {
    if (value.length === 0) {
      updateClearInput(value);
    }
    setKeyword(value);
  };

  const radioSearch = useRadioGroup({
    name: "search",
    defaultValue: "receiver",
    onChange: (e) => setQueryPageFilterType(e),
  });

  const searchGroup = radioSearch.getRootProps();

  useEffect(() => {
    setKeyword(keywordData);
  }, [keywordData]);

  return (
    <HStack>
      <HStack {...searchGroup}>
        {searchOptions.map(({ label, value }) => {
          const searchRadio = radioSearch.getRadioProps({ value });
          return (
            <RadioCard key={value} {...searchRadio} px={5} py={2} value={value}>
              {label}
            </RadioCard>
          );
        })}
      </HStack>
      <Input
        borderRadius={15}
        type="text"
        placeholder="Pesquisar..."
        value={keyword}
        onChange={(e) => handleClearInput(e.target.value)}
      />
      <Button onClick={() => handleClickButton()}>
        <SearchIcon />
      </Button>
    </HStack>
  );
}
