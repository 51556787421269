/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import ProtectedRoute from "components/ProtectedRoute/protectedRoute";

// Custom Chakra theme
import theme from "theme/theme.js";

import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import UiProvider from "contexts/UiContext";
import { ChakraProvider } from "@chakra-ui/react";

const queryClient = new QueryClient({});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme} resetCss={false}>
      <UiProvider>
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <ProtectedRoute path={`/admin/resgates`} component={AdminLayout} />
            <ProtectedRoute path={`/admin/exportar`} component={AdminLayout} />
            <ProtectedRoute
              path={`/admin/estatisticas`}
              component={AdminLayout}
            />
            <Redirect from={`/admin`} to="/admin/estatisticas" />
            <Redirect from={`/`} to="/admin/estatisticas" />
          </Switch>
        </HashRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </UiProvider>
    </ChakraProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);
