import React, { useEffect } from "react";
import { Chart, registerables } from "chart.js";

import { Flex, HStack, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { graphic } from "services/graphic";

// Registre os controladores necessários
Chart.register(...registerables);

export default function Graphic() {
  useEffect(() => {
    graphic("/dashboard/rescues").then((API) => {
      const data = {
        labels: API.data.map((e) => e.item),
        datasets: [
          {
            data: API.data.map((e) => e.rescues),
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#32CD32"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#32CD32"],
          },
        ],
      };
      const ctx = document.getElementById("rescuesChart").getContext("2d");
      if (ctx) {
        const config = {
          type: "pie",
          data: data,
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: "Produtos Resgatados",
              },
            },
          },
        };

        new Chart(ctx, config);
      }
    });
    graphic("/dashboard/rescues_by_person").then((API) => {
      const data = {
        labels: API.data.map((e) => e.personQty),
        datasets: [
          {
            data: API.data.map((e) => e.itemQty),
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#32CD32"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#32CD32"],
          },
        ],
      };
      const ctx = document
        .getElementById("rescuesByPersonChart")
        .getContext("2d");
      if (ctx) {
        const config = {
          type: "doughnut",
          data: data,
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: "Produtos Resgatados X Dia",
              },
            },
          },
        };
        new Chart(ctx, config);
      }
    });
    graphic("/dashboard/daily_rescues").then((API) => {
      const data = {
        labels: API.data.map((e) => e.item),
        datasets: API.data.map((e) => {
          return {
            label: e.item,
            data: e.rescues,
            borderColor: "#FF6384",
            backgroundColor:"#FF6384",
          };
        }),
      };
      const ctx = document.getElementById("dailyRescuesChart").getContext("2d");
      if (ctx) {
        const config = {
          type: "bar",
          data: data,
          options: {
            indexAxis: "y",
            // Elements options apply to all of the options unless overridden in a dataset
            // In this case, we are setting the border of each horizontal bar to be 2px wide
            elements: {
              bar: {
                borderWidth: 2,
              },
            },
            responsive: true,
            plugins: {
              legend: {
                position: "right",
              },
              title: {
                display: true,
                text: "Resgates X Dias",
              },
            },
          },
        };
        new Chart(ctx, config);
      }
    });
  }, []);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px" justifyContent="space-between">
          <Text fontSize="xl" color="gray.700" fontWeight="bold">
            Graphic
          </Text>
        </CardHeader>
        <CardBody>
          <div style={{ width: "500px", height: "500px" }}>
            <HStack>
              <Text fontSize="xl" color="gray.700" fontWeight="bold">
                Produtos Resgatados
              </Text>
            </HStack>
            <br />
            <HStack>
              <div style={{ width: "400px", height: "400px" }}>
                <canvas
                  id="rescuesChart"
                  width={400}
                  height={400}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </HStack>
          </div>
        </CardBody>
        <CardBody>
          <div style={{ width: "500px", height: "500px" }}>
            <HStack>
              <Text fontSize="xl" color="gray.700" fontWeight="bold">
                Produtos Resgatados X Dia
              </Text>
            </HStack>
            <br />
            <HStack>
              <div style={{ width: "400px", height: "400px" }}>
                <canvas
                  id="rescuesByPersonChart"
                  width={400}
                  height={400}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </HStack>
          </div>
        </CardBody>
        <CardBody>
          <div style={{ width: "1000px", height: "1000px" }}>
            <HStack>
              <Text fontSize="xl" color="gray.700" fontWeight="bold">
                Resgates X Dias
              </Text>
            </HStack>
            <br />
            <HStack>
              <div style={{ width: "1000px", height: "1000px" }}>
                <canvas
                  id="dailyRescuesChart"
                  width={400}
                  height={400}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </HStack>
          </div>
        </CardBody>
      </Card>
    </Flex>
  );
}
