import moment from "moment";

export const initialState = {
  from: null,
  to: null,
  download: false,
};

export const EXPORT_CLICKED = "EXPORT_CLICKED";
export const EXPORT_CLICKED_OFF = "EXPORT_CLICKED_OFF";

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case EXPORT_CLICKED:
      return {
        ...state,
        queryFrom: moment(payload.from).format("YYYY-MM-DD 00:00:00"),
        queryTo: moment(payload.to).format("YYYY-MM-DD 23:59:59"),
        download: true,
      };
    case EXPORT_CLICKED_OFF:
      return {
        ...state,
        download: false,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};
