// import
import SignIn from "views/Auth/SignIn.js";

import { CartIcon, DocumentIcon } from "components/Icons/Icons";
import { RiFileExcelFill, RiBarChart2Fill } from "react-icons/ri";
import Ramsoms from "views/Dashboard/Ramsoms/Ramsoms";
import Export from "views/Dashboard/Export/Export";
import Graphic from "views/Dashboard/Estatisticas/Graphic";

var dashRoutes = [
  {
    path: "/resgates",
    name: "Resgates",
    icon: <CartIcon color="inherit" />,
    component: Ramsoms,
    layout: "/admin",
    show: true,
  },
  {
    path: "/exportar",
    name: "Exportar",
    icon: <RiFileExcelFill color="inherit" />,
    component: Export,
    layout: "/admin",
    show: true,
  },
  {
    path: "/estatisticas",
    name: "Estatísticas",
    icon: <RiBarChart2Fill color="inherit" />,
    // TODO! chamar nova pagina aqui
    component: Graphic,
    layout: "/admin",
    show: true,
  },
  {
    path: "/signin",
    name: "Sign In",
    icon: <DocumentIcon color="inherit" />,
    component: SignIn,
    layout: "/auth",
    show: false,
  },
];
export default dashRoutes;
