import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";

export const UiContext = React.createContext();

export default function UiProvider({ children }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Ramsom constants
  const [row, setRow] = useState({});
  const [address, setAddress] = useState({});
  const [collapse, setCollapse] = useState(false);

  function onNewNotification(notification) {
    toast({
      title: notification.title,
      description: notification.description,
      status: notification.status,
      duration: notification.duration ?? 5000,
      position: notification.position ?? "bottom",
      isClosable: notification.isClosable ?? true,
    });
  }

  function onNewDetails(ramsom) {
    setRow(ramsom);
    setAddress(ramsom.address);
    onOpen();
  }

  const currencyFormatter = (currency) =>
    Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
      currency
    );

  const phoneFormatter = (phone) =>
    phone.number.length === 9
      ? `(${phone.ddd}) ${phone.number.substr(0, 1)} ${phone.number.substr(
          1,
          4
        )}-${phone.number.substr(4, 4)} ${
          phone.phoneTypeDescription ? `(${phone.phoneTypeDescription})` : ""
        }`
      : `(${phone.ddd}) ${phone.number.substr(0, 4)}-${phone.number.substr(
          3,
          4
        )} ${
          phone.phoneTypeDescription ? `(${phone.phoneTypeDescription})` : ""
        }`;

  const addressFormatter = (address) =>
    `${address.addressText}, ${address.number} - ${address.complement} - ${address.district}, ${address.city}/${address.state}`;

  const dateFormatter = (date) => moment(date).format("DD/MM/YYYY");

  return (
    <UiContext.Provider
      value={{ onNewNotification, onNewDetails, collapse, setCollapse }}
    >
      {children}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="1000px">
          <ModalHeader>Detalhes do Resgate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack divider={<StackDivider />} spacing="4">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  IDs
                </Heading>
                <Text pt="2" fontSize="sm">
                  ID: {row.participantLogin}
                </Text>
              </Box>
              <HStack>
                <Box flex={1}>
                  <Heading size="xs" textTransform="uppercase">
                    NOME
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {row.receiver}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Heading size="xs" textTransform="uppercase">
                    EMAIL
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {row.email}
                  </Text>
                </Box>
              </HStack>
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  ENDEREÇO
                </Heading>
                <Text pt="2" fontSize="sm">
                  {addressFormatter(address)}
                </Text>
              </Box>
              <HStack>
                {row.phones
                  ? row.phones.map((phone, index) => (
                      <Box key={phone.id} flex={1}>
                        <Heading size="xs" textTransform="uppercase">
                          TELEFONE {index + 1}
                        </Heading>
                        <Text pt="2" fontSize="sm">
                          {phoneFormatter(phone)}
                        </Text>
                      </Box>
                    ))
                  : null}
              </HStack>
              <HStack>
                <Box flex={1}>
                  <Heading size="xs" textTransform="uppercase">
                    NF
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {row.nfNumber}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Heading size="xs" textTransform="uppercase">
                    RESGATE EM
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {dateFormatter(row.orderDate)}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Heading size="xs" textTransform="uppercase">
                    VALOR TOTAL
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {currencyFormatter(row.orderTotalValue)}
                  </Text>
                </Box>
                <Box flex={1}>
                  <Heading size="xs" textTransform="uppercase">
                    CÓDIGO DE RASTREIO
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    {row.trackingCode}
                  </Text>
                </Box>
              </HStack>
              <Box>
                <Heading as="h2" size="md" pb={5}>
                  Produto
                </Heading>
                <HStack>
                  <Image
                    boxSize="100px"
                    objectFit="cover"
                    src={row.vendorOrderModel?.[0].items?.[0].imageUrl}
                    fallback={<Spinner color="#ffd404" />}
                    fallbackSrc="https://via.placeholder.com/100"
                    alt="Dan Abramov"
                    mr={5}
                    borderRadius={15}
                  />
                  <VStack>
                    <Text>
                      {row.vendorOrderModel?.[0].items?.[0].name ?? ""}
                    </Text>
                  </VStack>
                </HStack>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </UiContext.Provider>
  );
}
