import axios from "axios";

const tokenKey = "token";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization2: `Bearer ${localStorage.getItem(tokenKey)}`,
  },
});

export default instance;
