import React, { useState, useContext } from "react";
import auth from "services/authService";

// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
// Assets
import nicklog from "./../../assets/img/logo-nick_22x.png";
import { UiContext } from "contexts/UiContext";

const SignIn = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { onNewNotification } = useContext(UiContext);

  const handleLogin = async () => {
    if (!username || !password) {
      onNewNotification({
        title: "Ops...",
        description: "Favor preencher todos os campos",
        status: "warning",
      });
      return;
    }

    setIsLoading(true);
    try {
      await auth.login({ username, password });
      window.location = "/admin/resgates";
    } catch (error) {
      setIsLoading(false);

      const { code, message } = error;

      onNewNotification({
        title: code,
        description: message,
        status: "error",
      });
    }
  };

  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "40%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Heading color="#ffd404" fontSize="32px" mb="10px">
              Bem vindo
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              Insira seu login e senha para logar
            </Text>
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input
                id="email-field"
                borderRadius="15px"
                mb="24px"
                fontSize="sm"
                type="text"
                placeholder="Seu email"
                size="lg"
                name={username}
                onChange={(e) => setUserName(e.target.value)}
              />
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Senha
              </FormLabel>
              <Input
                id="password-field"
                borderRadius="15px"
                mb="36px"
                fontSize="sm"
                type="password"
                placeholder="Sua senha"
                size="lg"
                name={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                isLoading={isLoading}
                fontSize="14px"
                type="submit"
                bg="#ffd404"
                w="100%"
                h="45"
                mb="20px"
                color="black"
                mt="20px"
                _hover={{
                  bg: "#edc605",
                }}
                _active={{
                  bg: "#ffd404",
                }}
                onClick={handleLogin}
              >
                Entrar
              </Button>
            </FormControl>
          </Flex>
        </Flex>
        <Flex
          h="100%"
          w={{ base: "none", md: "50%", lg: "60%" }}
          pl={{ base: "none", md: "100px", lg: "200px" }}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Image
            src={nicklog}
            alt="Logo Nicklog"
            display={{ base: "none", md: "block" }}
            w="100%"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SignIn;
